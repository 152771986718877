<template>
  <div class="container">
    <h1 class="mb-print-3">NATO Phonetic Alphabet Flashcards</h1>
    <div class="d-print-none d-flex justify-content-between mb-2">
      <div>
        Tap or click each flashcard to advance. Or download the PDF for offline
        study.
      </div>
      <div class="text-end ms-2">
        <a
          class="btn btn-sm btn-outline-dark mb-2 text-nowrap"
          href="/flashcards-from-learnthephoneticalphabet.com.pdf"
          download
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            height="1rem"
          >
            <path
              d="M181.9 256.1c-5-16-4.9-46.9-2-46.9 8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7 18.3-7 39-17.2 62.9-21.9-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8 4.5-18.5 11.6-46.6 6.2-64.2-4.7-29.4-42.4-26.5-47.8-6.8-5 18.3-.4 44.1 8.1 77-11.6 27.6-28.7 64.6-40.8 85.8-.1 0-.1.1-.2.1-27.1 13.9-73.6 44.5-54.5 68 5.6 6.9 16 10 21.5 10 17.9 0 35.7-18 61.1-61.8 25.8-8.5 54.1-19.1 79-23.2 21.7 11.8 47.1 19.5 64 19.5 29.2 0 31.2-32 19.7-43.4-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9 37.1 15.8 42.8 9 42.8 9z"
            />
          </svg>
          Download
        </a>
      </div>
    </div>

    <div id="flashcards" class="row">
      <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
        <div v-if="currentWord">
          <a
            v-if="showingFront"
            href="#"
            @click.prevent="flipCard()"
            class="display-1 p-5 border text-center bg-light d-block front"
            >{{ currentWord.charAt(0) }}</a
          ><a
            v-else
            href="#"
            @click.prevent="nextCard()"
            class="display-1 p-5 border text-center bg-light d-block back"
            >{{ currentWord }}</a
          >
        </div>
        <div v-else>
          <a
            href="#"
            @click.prevent="reset()"
            class="display-1 p-5 border text-center bg-light d-block text-decoration-none"
          >
            All Done!
          </a>
        </div>
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            :style="{ width: progress }"
          ></div>
        </div>
      </div>
    </div>

    <div class="mt-3 text-center">
      Type:
      <div class="btn-group btn-group-sm mt-1 mt-sm-0">
        <input
          v-model="type"
          value="random1x"
          @change="reset()"
          type="radio"
          class="btn-check"
          id="type-random-1x"
          autocomplete="off"
        />
        <label class="btn btn-outline-primary" for="type-random-1x"
          >Random 1x</label
        >

        <input
          v-model="type"
          value="random"
          @change="reset()"
          type="radio"
          class="btn-check"
          id="type-random-repeating"
          autocomplete="off"
        />
        <label class="btn btn-outline-primary" for="type-random-repeating"
          >Random repeating</label
        >

        <input
          v-model="type"
          value="ordered1x"
          @change="reset()"
          type="radio"
          class="btn-check"
          id="type-ordered-1x"
          autocomplete="off"
        />
        <label class="btn btn-outline-primary" for="type-ordered-1x"
          >Ordered 1x</label
        >
      </div>
    </div>
  </div>
</template>

<script>
import alphabet from "../alphabet";

export default {
  created() {
    this.reset();
  },

  data() {
    return {
      showingFront: true,
      currentWord: "",
      type: "random1x",
      wordStack: [],
    };
  },

  methods: {
    reset() {
      if (this.type == "random1x" || this.type == "random") {
        this.wordStack = alphabet
          .map((value) => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value);
      } else if (this.type == "ordered1x") {
        this.wordStack = [...alphabet];
      }

      this.currentWord = this.getNextWord();
    },

    flipCard() {
      this.showingFront = false;
    },

    nextCard() {
      if (this.type === "random" && this.wordStack.length == 0) {
        this.reset();
      } else {
        this.currentWord = this.getNextWord();
      }

      this.showingFront = true;
    },

    getNextWord() {
      return this.wordStack.shift();
    },
  },

  computed: {
    progress() {
      if (!this.currentWord || this.type === "random") {
        return "100%";
      }
      return (1 - (this.wordStack.length + 1) / 26) * 100 + "%";
    },
  },
};
</script>
