<template>
  <div class="container">
    <div class="display-4 text-danger">404 - Not Found</div>
    <p>This page was not found.</p>
    <p>
      Go back to the
      <router-link :to="{ name: 'home' }">home page</router-link>.
    </p>
  </div>
</template>
