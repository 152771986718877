<template>
  <div class="container">
    <h1>About This Website</h1>
    <p>
      In 2022, I decided to pursue my Amateur Radio license (KD9VGQ). One of the
      procedures required in licensed radio usage requires the repetition of
      your call sign. These are shared using the NATO phonetic alphabet -
      something I realized I didn't actually know. I decided to put together
      some flashcards to help myself learn - and realized maybe others could
      benefit from this. So, as a
      <a href="https://morebetterfaster.io">web developer and consultant</a>, I
      thought it would be nice to put together this site for everyone to use.
    </p>
    <h2>Supporting Information</h2>
    <ol id="supporting-information">
      <li id="i">
        <a href="https://en.wikipedia.org/wiki/NATO_phonetic_alphabet"
          >This Wikipedia article</a
        >
        has more detail about the usage and history of the NATO phonetic
        alphabet.
      </li>
      <li id="ii">
        For some reason,
        <a href="https://aaronsaray.com">my last name</a> sometimes comes across
        as starting with an <strong>F</strong> instead of an <strong>S</strong>.
        I grew up hearing my mom say <q>S as in Samuel</q> but I never thought
        that was right.
      </li>
      <li id="iii">
        The "Z as in Xylophone" was a real thing that my bosses once said on a
        technical support call. He immediately realized his mistake, but it was
        too late. I'll remember this forever.
      </li>
    </ol>
    <h2>Contact</h2>
    <p>
      You can contact me by visiting
      <a href="https://morebetterfaster.io/">More Better Faster</a>.
    </p>
  </div>
</template>
