<template>
  <header>
    <div class="container-fluid p-3">
      <nav class="nav justify-content-center justify-content-md-end">
        <router-link :to="{ name: 'home' }" class="nav-link">Home</router-link>
        <router-link :to="{ name: 'chart' }" class="nav-link"
          >Chart</router-link
        >
        <router-link :to="{ name: 'flashcards' }" class="nav-link"
          >Flashcards</router-link
        >
      </nav>
    </div>
  </header>
  <main class="pb-2">
    <router-view />
  </main>
  <footer class="p-3">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-12 col-md-6 mb-3 mb-md-0 text-center text-md-start">
          &copy; {{ new Date().getFullYear() }}
          <a href="https://morebetterfaster.io">More Better Faster</a>
        </div>
        <div class="col-12 col-md-6">
          <nav class="nav justify-content-center justify-content-md-end">
            <router-link :to="{ name: 'home' }" class="nav-link"
              >Home</router-link
            >
            <router-link :to="{ name: 'chart' }" class="nav-link"
              >Chart</router-link
            >
            <router-link :to="{ name: 'flashcards' }" class="nav-link"
              >Flashcards</router-link
            >
            <router-link :to="{ name: 'about' }" class="nav-link"
              >About</router-link
            >
          </nav>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
$text: #242e38;
$primary: #4596cf;

@import "~bootstrap/scss/bootstrap";

body {
  -webkit-print-color-adjust: exact;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
main {
  flex-grow: 1;
}

a {
  font-weight: bold;
}
h1 {
  color: #002244;
}
h2 {
  color: #224466;
}

header {
  nav {
    font-size: 0.85rem;
    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
  }
}

footer {
  font-size: 0.85rem;
  color: $text-muted;
  background-color: $text;
  a {
    color: $text-muted;

    &.nav-link {
      color: #ffffff;
      font-weight: 300;
    }
  }
}

.home-option-link {
  svg {
    height: 2rem;
  }
}

#supporting-information li {
  list-style-type: lower-roman;
}

#chart {
  font-size: 16px;
  border: 0.3em solid shade-color($primary, 5%);
  background: linear-gradient(
    180deg,
    $primary 0%,
    shade-color($primary, 12%) 100%
  );

  column-count: 3;
  column-fill: balance;
  list-style: none;
  margin: 0;
  padding: 0.5em;

  li {
    margin: 0.2em 0;
    span {
      font-weight: bold;
      background-color: #242e38;
      color: #ffffff;
      display: inline-block;
      height: 1.5em;
      width: 1.5em;
      text-align: center;
      margin-right: 0.15em;
    }
  }
}
@include media-breakpoint-up(md) {
  #chart {
    font-size: 2vw;
  }
}
#chart-attribution {
  background-color: shade-color($primary, 5%);
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  padding: 0.5rem;
}
@media print {
  header,
  footer {
    display: none;
  }
  .mb-print-3 {
    margin-bottom: $spacer;
  }
  #chart {
    border-bottom: none;
  }
}

#flashcards {
  .front,
  .back {
    text-decoration: none;
    color: $text;
  }
  .front,
  .back::first-letter {
    font-weight: bolder;
    color: $primary;
  }
  .progress {
    height: 0.2rem;
  }
}
</style>
