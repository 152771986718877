<template>
  <div class="container">
    <h1>Learn the Phonetic Alphabet</h1>
    <p>
      The <strong>International Radiotelephony Spelling Alphabet</strong>,
      commonly known as the <strong>NATO phonetic alphabet</strong>,
      <strong>NATO spelling alphabet</strong>,
      <strong>ICAO phonetic alphabet</strong> or
      <strong>ICAO spelling alphabet</strong>, is the most widely used
      radiotelephone spelling alphabet.
      <sub
        ><router-link :to="{ name: 'about', hash: '#i' }">i</router-link></sub
      >
    </p>
    <h2>Why Should I Learn This?</h2>
    <p>
      The NATO phonetic alphabet is useful for many reasons, the most common
      being enhancing spelled words on a poor quality telephone connection
      <sub
        ><router-link :to="{ name: 'about', hash: '#ii' }">ii</router-link></sub
      >
      or for Amateur Radio (HAM) call signs. Never make embarrassing mistakes
      like "Z as in Xylophone" again
      <sub
        ><router-link :to="{ name: 'about', hash: '#iii' }"
          >iii</router-link
        ></sub
      >.
    </p>
    <h2>How can I learn?</h2>
    <p class="mb-0">
      It just takes practice. But, hopefully this website can help. Check out
      these options to help you learn:
    </p>
    <div class="row mb-4">
      <div class="col text-center mt-4">
        <router-link :to="{ name: 'chart' }" class="home-option-link">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              d="M448 32C483.3 32 512 60.65 512 96V416C512 451.3 483.3 480 448 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H448zM152 96H64V160H152V96zM208 160H296V96H208V160zM448 96H360V160H448V96zM64 288H152V224H64V288zM296 224H208V288H296V224zM360 288H448V224H360V288zM152 352H64V416H152V352zM208 416H296V352H208V416zM448 352H360V416H448V352z"
            />
          </svg>
          <span class="d-block">Chart</span>
        </router-link>
      </div>
      <div class="col text-center mt-4">
        <router-link :to="{ name: 'flashcards' }" class="home-option-link">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path
              d="M208 256c35.35 0 64-28.65 64-64c0-35.35-28.65-64-64-64s-64 28.65-64 64C144 227.3 172.7 256 208 256zM464 232h-96c-13.25 0-24 10.75-24 24s10.75 24 24 24h96c13.25 0 24-10.75 24-24S477.3 232 464 232zM240 288h-64C131.8 288 96 323.8 96 368C96 376.8 103.2 384 112 384h192c8.836 0 16-7.164 16-16C320 323.8 284.2 288 240 288zM464 152h-96c-13.25 0-24 10.75-24 24s10.75 24 24 24h96c13.25 0 24-10.75 24-24S477.3 152 464 152zM512 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h448c35.35 0 64-28.65 64-64V96C576 60.65 547.3 32 512 32zM528 416c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16V96c0-8.822 7.178-16 16-16h448c8.822 0 16 7.178 16 16V416z"
            />
          </svg>
          <span class="d-block">Flashcards</span></router-link
        >
      </div>
    </div>
  </div>
</template>
