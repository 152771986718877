<template>
  <div class="container">
    <h1 class="mb-print-3">NATO Phonetic Alphabet Chart</h1>
    <div class="d-print-none d-flex justify-content-between mb-2">
      <div>Use this chart to study. Print or Download a PDF.</div>
      <div class="text-end">
        <a
          class="btn btn-sm btn-outline-dark mb-2"
          href="/chart-from-learnthephoneticalphabet.com.pdf"
          download
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            height="1rem"
          >
            <path
              d="M181.9 256.1c-5-16-4.9-46.9-2-46.9 8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7 18.3-7 39-17.2 62.9-21.9-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8 4.5-18.5 11.6-46.6 6.2-64.2-4.7-29.4-42.4-26.5-47.8-6.8-5 18.3-.4 44.1 8.1 77-11.6 27.6-28.7 64.6-40.8 85.8-.1 0-.1.1-.2.1-27.1 13.9-73.6 44.5-54.5 68 5.6 6.9 16 10 21.5 10 17.9 0 35.7-18 61.1-61.8 25.8-8.5 54.1-19.1 79-23.2 21.7 11.8 47.1 19.5 64 19.5 29.2 0 31.2-32 19.7-43.4-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9 37.1 15.8 42.8 9 42.8 9z"
            />
          </svg>
          Download
        </a>
        <button class="btn btn-sm btn-outline-dark ms-3 mb-2" @click="print()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            height="1rem"
          >
            <path
              d="M448 192H64C28.65 192 0 220.7 0 256v96c0 17.67 14.33 32 32 32h32v96c0 17.67 14.33 32 32 32h320c17.67 0 32-14.33 32-32v-96h32c17.67 0 32-14.33 32-32V256C512 220.7 483.3 192 448 192zM384 448H128v-96h256V448zM432 296c-13.25 0-24-10.75-24-24c0-13.27 10.75-24 24-24s24 10.73 24 24C456 285.3 445.3 296 432 296zM128 64h229.5L384 90.51V160h64V77.25c0-8.484-3.375-16.62-9.375-22.62l-45.25-45.25C387.4 3.375 379.2 0 370.8 0H96C78.34 0 64 14.33 64 32v128h64V64z"
            />
          </svg>
          Print
        </button>
      </div>
    </div>

    <ul id="chart">
      <li v-for="word in alphabet" v-bind:key="word">
        <span>{{ word.charAt(0) }}</span
        >{{ word.substring(1) }}
      </li>
    </ul>
    <div id="chart-attribution" class="d-none d-print-block">
      learnthephoneticalphabet.com
    </div>
  </div>
</template>

<script>
import alphabet from "../alphabet";

export default {
  data() {
    return {
      alphabet,
    };
  },

  methods: {
    print() {
      window.print();
    },
  },
};
</script>
